import React from 'react';
import { hideVisually } from 'polished';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import CliniqueList from '../components/CliniqueList';
import Map from '../components/Map';
import { PrivacyPolicyNote } from '../components/PrivacyPolicy';

const MapContainer = styled.div`
  display: block;
  width: 100vw;
  height: ${(920 / 1920) * 100}vw;
  max-height: 920px;
`;

const ContactPage = ({ data }) => {
  const {
    cliniques: { edges: cliniques },
  } = data;

  const mapMarkers = cliniques.map(({ node }) => ({
    position: {
      lat: Number(node.address.coordonate.lat),
      lng: Number(node.address.coordonate.lng),
    },
  }));

  return (
    <Layout>
      <SEO
        title='Contact'
        description='Retrouvez toutes les coordonnées de nos cliniques Maxillo Québec Lebourgneuf et Lévis où nos spécialistes en chirurgie buccale et maxillo-faciale vous accueilleront.'
      />

      <h1 css={hideVisually}>Contact</h1>

      <CliniqueList />

      <PrivacyPolicyNote />

      <MapContainer>
        <Map markers={mapMarkers} />
      </MapContainer>
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    cliniques: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            address: PropTypes.shape({
              coordonate: PropTypes.shape({
                lat: PropTypes.number.isRequired,
                lng: PropTypes.number.isRequired,
              }).isRequired,
            }).isRequired,
          }).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export default ContactPage;

export const query = graphql`
  query {
    cliniques: allSanityLocation {
      edges {
        node {
          address {
            coordonate {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;
